var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRole
    ? _c(
        "div",
        { staticClass: "cart_role_info" },
        [
          _c("div", [
            _c("div", { staticClass: "info flex-start" }, [
              _c("div", { staticClass: "user_default_avatar" }, [
                _c("img", {
                  attrs: { src: require("images/icon/icon-avatar.png") },
                }),
              ]),
              _c("div", [
                _c("p", { staticClass: "role_name" }, [
                  _vm._v(_vm._s(_vm.userInfo.gameRoleName)),
                ]),
                _c("p", { staticClass: "role_other" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$sys("account.server")) +
                        "：" +
                        _vm._s(_vm.userInfo.gameZoneName)
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$sys("account.roleID")) +
                        "：" +
                        _vm._s(_vm.userInfo.gameRoleCode)
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("PopRoleSelect", {
            on: {
              close: function ($event) {
                _vm.isPopRole = false
              },
            },
            model: {
              value: _vm.isPopRole,
              callback: function ($$v) {
                _vm.isPopRole = $$v
              },
              expression: "isPopRole",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }