var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "step_nav flex-center" }, [
    _c(
      "li",
      {
        staticClass: "flex-center flex-column-center",
        class: { active: _vm.stepIndex > 0 },
      },
      [
        _c("span", { staticClass: "num" }, [_vm._v("1")]),
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$sys("account.loginAccount"))),
        ]),
      ]
    ),
    _c(
      "li",
      {
        staticClass: "flex-center flex-column-center",
        class: { active: _vm.stepIndex > 1 },
      },
      [
        _c("span", { staticClass: "num" }, [_vm._v("2")]),
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$sys("account.selectRole"))),
        ]),
        _c("span", { staticClass: "tail" }),
      ]
    ),
    _c(
      "li",
      {
        staticClass: "flex-center flex-column-center",
        class: { active: _vm.stepIndex > 2 },
      },
      [
        _c("span", { staticClass: "num" }, [_vm._v("3")]),
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$sys("account.startReachrge"))),
        ]),
        _c("span", { staticClass: "tail" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }