var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "pc_form_role",
      attrs: { model: _vm.$data, inline: true, rules: _vm.rules },
    },
    [
      _vm.hasRoles
        ? _c(
            "el-form-item",
            { attrs: { prop: "roleVal" } },
            [
              _c("span", { staticClass: "defined_label" }, [
                _vm._v(_vm._s(_vm.$sys("account.roleName"))),
              ]),
              _c(
                "el-select",
                {
                  staticClass: "select_style role-select",
                  attrs: {
                    filterable: "",
                    placeholder: _vm.$sys("account.roleName"),
                  },
                  model: {
                    value: _vm.roleVal,
                    callback: function ($$v) {
                      _vm.roleVal = $$v
                    },
                    expression: "roleVal",
                  },
                },
                _vm._l(_vm.roleList, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.gameRoleCode,
                      staticStyle: { height: "80px" },
                      attrs: {
                        label: item.gameRoleName,
                        value: item.gameRoleCode,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            display: "flex",
                            "flex-direction": "column",
                            "line-height": "22px",
                            padding: "10px 0",
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(item.gameRoleName) +
                                " (LV." +
                                _vm._s(item.gameRoleLevel || "") +
                                ")"
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                color: "#B9B9B9",
                                padding: "0",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$sys("account.server")) +
                                      "：" +
                                      _vm._s(item.gameZoneName)
                                  ),
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$sys("account.roleID")) +
                                    "：" +
                                    _vm._s(item.gameRoleCode)
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "block",
                                    "margin-top": "3px",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$sys("account.latestLogin")) +
                                      "：" +
                                      _vm._s(
                                        _vm.formatDateTime(
                                          item.lastLogoutTime
                                        ) || ""
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      item.gameRoleCode === _vm.latestLoginRoleCode
                        ? _c("span", { staticClass: "pc-form-role__mark" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$sys("account.latestLogin")) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hasRoles && _vm.showCountry
        ? _c(
            "el-form-item",
            [
              _c("span", { staticClass: "defined_label" }, [
                _vm._v(_vm._s(_vm.$sys("account.country"))),
              ]),
              _c(
                "el-select",
                {
                  staticClass: "select_style",
                  attrs: { placeholder: _vm.$sys("form.placeholder.select") },
                  model: {
                    value: _vm.countryVal,
                    callback: function ($$v) {
                      _vm.countryVal = $$v
                    },
                    expression: "countryVal",
                  },
                },
                _vm._l(_vm.countryLang, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    staticClass: "m-select",
                    attrs: {
                      label: `${item.country}（${item.value}）`,
                      value: item.value,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.hasRoles
        ? _c("div", { staticClass: "tips_no_roles" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$sys("account.noRoles", { name: `【${_vm.nickname}】` })
                ) +
                " "
            ),
          ])
        : _vm._e(),
      _c("el-form-item", [
        _c(
          "div",
          { staticClass: "tips_text" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn_submit",
                attrs: { type: "primary", loading: _vm.isBtnLoading },
                on: { click: _vm.onSubmit },
              },
              [_vm._v(" " + _vm._s(_vm.$sys("btn.confirm")) + " ")]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showNoRole,
                    expression: "showNoRole",
                  },
                ],
                staticClass: "tips_text_a",
                on: { click: _vm.logout },
              },
              [_vm._v(_vm._s(_vm.$sys("warn.noFindRole")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }