import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { apiBindRole } from '@/api/user'
import { removeSelectedRole, SELECTED_ROLE_STORE_KEY } from '@/utils/storage'
import { logLogin } from '@/utils/log'
import { formatDateTime } from '@/utils'

export default {
  props: {
    hasRoles: {
      type: Boolean,
      default() {
        return true
      }
    },
    showCountry: {
      type: Boolean,
      default: false
    },
    showNoRole: {
      type: Boolean,
      default: false
    },
    roleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      roleVal: null,
      roleName: null,
      isBtnLoading: false,
      latestLoginRoleCode: null
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('game', ['country', 'countryLang']),
    ...mapGetters(['isMobile', 'lang', 'nickname']),
    countryVal: {
      get() {
        return this.country
      },
      set(country) {
        this.chooseCountry({ country })
      }
    },
    serverStatus() {
      return [this.roleList]
    }
  },
  created() {
    this.parseLocalStoreRole()
  },
  methods: {
    formatDateTime,
    ...mapMutations('user', ['SET_USER_INFO']),
    ...mapActions('game', ['chooseCountry']),
    ...mapActions('user', ['logout']),
    async onSubmit() {

      if (!this.hasRoles) {
        this.logout()
        return
      }

      try {
        await this.$refs.form.validate()


        this.isBtnLoading = true
        const serverVal = this.roleList.find(role => role.gameRoleCode === this.roleVal)?.gameZoneCode
        const result = await apiBindRole(serverVal, this.roleVal)
        this.isBtnLoading = false

        if (result && result.code === 0) {
          const roleInfo = this.roleList.find(item => item.gameRoleCode === this.roleVal)

          this.SET_USER_INFO(roleInfo)

          this.$emit('confirm')
          this.recordRole(this.roleList, roleInfo)

          logLogin(this.roleVal)
        }

      } catch (e) {
        console.error(e)
      }

    },
    resetDefault() {
      const firstItem = this.$store.state.user.userInfo || this.roleList[0]
      if (firstItem) {
        const { gameRoleCode, gameRoleName } = firstItem
        this.roleVal = gameRoleCode
        this.roleName = gameRoleName
      }
      return firstItem
    },
    recordRole(roleList, selectedValue) {
      const index = roleList.map(s => s.gameRoleCode).indexOf(selectedValue.gameRoleCode)
      if (index > 0) {
        const tmp = roleList[index]
        this.roleList[index] = roleList[0]
        this.roleList[0] = tmp
        localStorage.setItem(SELECTED_ROLE_STORE_KEY, JSON.stringify(selectedValue))
      }
    },
    parseLocalStoreRole() {
      const roleInfo = localStorage.getItem(SELECTED_ROLE_STORE_KEY)
      try {
        this.selectedRole = roleInfo ? JSON.parse(roleInfo) : null
      } catch (e) {
        removeSelectedRole()
        this.selectedRole = null
      }
    }
  },
  watch: {
    serverStatus() {
      if (this.roleList.length > 0) {
        const firstItem = this.resetDefault()
        this.selectedRole = this.selectedRole || firstItem
        this.latestLoginRoleCode = this.roleList[0].gameRoleCode
        this.recordRole(this.roleList, this.selectedRole)
      }
    },
    roleList(nv,ov) {
      if(nv && nv.length){
        const { gameZoneCode, gameZoneName, gameRoleCode, gameRoleName } = this.userInfo
        const role = this.roleList.find(item => item.gameRoleCode === gameRoleCode) || this.roleList[0]
        if (role) {
          this.serverName = role.gameZoneName
          this.roleVal = role.gameRoleCode
          this.roleName = role.gameRoleName
        }
      }

    },
    lang: {
      async handler() {
        if (!this.isMobile) {
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
      },
      immediate: true
    }
  }
}