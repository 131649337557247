<template>
  <div class='step_role'>
    <components
      ref='roleComponent'
      :is="isMobile ? 'FormM' : 'FormPc'"
      :roleList='roleList'
      :showCountry='showCountry'
      :showNoRole='showNoRole'
      :has-roles='hasRoles'
      @confirm="$emit('confirm')"
    ></components>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { apiRoleList } from '@/api/user'
import FormPc from './Pc'
import FormM from './M'

export default {
  name: 'StepRole',
  components: {
    FormPc,
    FormM
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: true
    },
    showNoRole: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      handleNoPermission: function() {
      }
    }
  },
  async created() {
    await this.handleRoleData()
  },
  data() {
    return {
      roleList: [],
      hasRoles: true
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  watch: {
    async isEdit(value) {
      if (value) {
       await this.handleRoleData()
      }
    },
    isOpen(nv, ov) {
      if (!nv && this.$refs.roleComponent) {

        this.$refs.roleComponent.resetDefault()
      }
    }
  },
  methods: {
    async handleRoleData() {
      try {
        const result = await apiRoleList()
        if (result && result.code === 0) {
          this.roleList = result.data
          this.hasRoles = !!this.roleList.length
        } else {
          this.hasRoles = false
        }

      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang='scss' scoped></style>