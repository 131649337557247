<template>
  <div class='step_login flex-column-center'>

    <div class='flex-column-center'>

      <div class='game_icon_box flex-center'>
        <img :src="require('images/game/xy-icon.png')" alt='game-icon' class='game_icon'>
      </div>

      <div class='game_name'>{{ $xy('game.name') }}</div>

      <a href='javascript:;' class='btn_login flex-center' @click='handleLogin'>{{ $sys('btn.login') }}</a>

      <p class='tips'>{{ $sys('warn.loginTips') }}</p>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StepLogin',
  mounted() {
    const showLoginPop = this.$route.params.showLoginPop
    if (showLoginPop) {
      this.login()
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    handleLogin() {
      this.login()
    }
  }
}
</script>

<style lang='scss' scoped></style>