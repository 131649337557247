var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.imageUrl
    ? _c("img", {
        staticClass: "goods_banner clickable",
        attrs: { src: _vm.imageUrl, alt: "" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }