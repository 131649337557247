import { render, staticRenderFns } from "./Pc.vue?vue&type=template&id=4a678114"
import script from "./Pc.vue?vue&type=script&lang=js"
export * from "./Pc.vue?vue&type=script&lang=js"
import style0 from "./Pc.vue?vue&type=style&index=0&id=4a678114&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/gitlab-runner/builds/mrZpYrznL/0/maggie-oversea/maggie-oversea-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a678114')) {
      api.createRecord('4a678114', component.options)
    } else {
      api.reload('4a678114', component.options)
    }
    module.hot.accept("./Pc.vue?vue&type=template&id=4a678114", function () {
      api.rerender('4a678114', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Form/FormRoles/Pc.vue"
export default component.exports