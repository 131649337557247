<template>
  <el-form :model='$data' :inline='true' ref='form' :rules='rules' class='pc_form_role'>
    <el-form-item prop='roleVal' v-if='hasRoles'>
      <span class='defined_label'>{{ $sys('account.roleName') }}</span>
      <el-select
        filterable
        class='select_style role-select'
        v-model='roleVal'
        :placeholder="$sys('account.roleName')"
      >
        <el-option
          style='height: 80px;' v-for='item in roleList'
          :key='item.gameRoleCode'
          :label='item.gameRoleName'
          :value='item.gameRoleCode'
        >
          <div
            style='position:relative;display: flex;flex-direction: column;line-height: 22px;padding:10px 0;'>
            <b>{{ item.gameRoleName }} (LV.{{ item.gameRoleLevel || '' }})</b>
            <div style='font-size: 12px;color:#B9B9B9; padding:0;'>
              <span style="margin-right: 10px">{{ $sys('account.server') }}：{{ item.gameZoneName }}</span>
              <span>{{ $sys('account.roleID') }}：{{ item.gameRoleCode }}</span>
              <span style="display: block;margin-top:3px;">{{ $sys('account.latestLogin') }}：{{ formatDateTime(item.lastLogoutTime) || '' }}</span>
            </div>
          </div>

          <span v-if='item.gameRoleCode === latestLoginRoleCode' class='pc-form-role__mark'>
            {{ $sys('account.latestLogin') }}
          </span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item v-if='hasRoles && showCountry'>
      <span class='defined_label'>{{ $sys('account.country') }}</span>
      <el-select
        class='select_style'
        v-model='countryVal'
        :placeholder="$sys('form.placeholder.select')">
        <el-option
          class='m-select'
          v-for='item in countryLang'
          :key='item.value'
          :label='`${item.country}（${item.value}）`'
          :value='item.value'
        >
        </el-option>
      </el-select>
    </el-form-item>

    <div v-if='!hasRoles' class='tips_no_roles'>
      {{ $sys('account.noRoles', { name: `【${nickname}】` }) }}
    </div>

    <el-form-item>
      <div class='tips_text'>
        <el-button type='primary' class='btn_submit' :loading='isBtnLoading' @click='onSubmit'>
          {{ $sys('btn.confirm') }}
        </el-button>
        <a class='tips_text_a' v-show='showNoRole' @click='logout'>{{ $sys('warn.noFindRole') }}</a>
      </div>
    </el-form-item>

  </el-form>

</template>

<script>
import { Form, FormItem, Select, Button, Option } from 'element-ui'
import mixin from './mixin'

export default {
  data() {
    return {
      rules: {
        roleVal: [{ required: true, message: this.$sys('warn.roleEmpty') }]
      }
    }
  },
  mixins: [mixin],
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Button.name]: Button,
    [Option.name]: Option
  }
}
</script>

<style lang='scss'>
.pc_form_role {
  width: 400px;
  margin: 0 auto 0;

  .select_style {
    width: 400px;
  }

  .defined_label {
    position: absolute;
    left: 10px;
    z-index: 10;
  }

  .el-form-item {
    .el-input__inner[type="text"]::placeholder {
      text-align: right !important;
    }

    .el-input__inner {
      padding-left: 0;
      text-align: right !important;
    }
  }

  .btn_submit {
    width: 220px;
    height: 50px;
    background-color: #FC892E;
    border: none;
    margin: 24px 90px 0 90px;
    font-weight: bold;
    font-size: 25px;

  }

  .tips_text {
    text-align: center;

    .tips_text_a {
      color: #1890ff;
      cursor: pointer;
    }
  }

  .tips_no_roles {
    color: #666666;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 50px 0;
  }

}

.pc-form-role__mark {
  display: block;
  position: absolute;
  padding: 0 10px;
  background-color: #FE7B0F;
  border-radius: 6px;
  border-bottom-left-radius: 15px;
  right: 0;
  top: 0;
  color: #fff;
  font-size: 12px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>