<template>
  <img v-if="imageUrl" :src="imageUrl" class="goods_banner clickable" alt="">
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      imageUrl: null,
    }
  },
  computed:{
    ...mapState('user', ['userInfo']),
    ...mapGetters(['country', 'lang']),
    status() {
      return [this.country, this.lang, this.userInfo]
    }
  },
  methods: {
    ...mapActions('game', ['getPosition'])
  },
  watch:{
    status: {
      async handler() {
        const arr = await this.getPosition(2)
        this.imageUrl = arr[0] && arr[0].value
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>