var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods_section" },
    [
      _c("Title", { attrs: { name: _vm.$sys("mall.goodsTitle") } }),
      _c("DynamicCopywriting"),
      _vm.goodsList.length > 0
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "goods_list flex-start",
            },
            _vm._l(_vm.goodsList, function (item, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    staticClass: "item p-r flex-column-center clickable",
                    class: {
                      active: _vm.goodsInfo.commodityCode == item.commodityCode,
                    },
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.handleChoose(item)
                      },
                    },
                  },
                  [
                    item.cornerMarkText
                      ? _c("span", { staticClass: "corner-mark" }, [
                          _vm._v(_vm._s(item.cornerMarkText)),
                        ])
                      : _vm._e(),
                    item.isRecommend
                      ? _c("span", { staticClass: "hot" })
                      : _vm._e(),
                    _c("img", {
                      staticClass: "img",
                      attrs: { src: item.commodityIcon, alt: "" },
                    }),
                    _c("div", { staticClass: "goods-wrap" }, [
                      _c(
                        "p",
                        {
                          staticClass: "name ellipse",
                          attrs: { title: item.commodityName },
                        },
                        [_vm._v(_vm._s(item.commodityName))]
                      ),
                      _c("p", { staticClass: "descr" }, [
                        _vm._v(_vm._s(item.preferentialExplain)),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "prize",
                          attrs: {
                            title: _vm.toCurrencyThousand(
                              item.commoditySellPrice
                            ),
                          },
                        },
                        [
                          item.currencyFrontLocation == 11
                            ? [
                                _c("a", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.toCurrencyThousand(
                                          item.commoditySellPrice
                                        )
                                      ) +
                                      " " +
                                      _vm._s(item.commodityCurrency)
                                  ),
                                ]),
                              ]
                            : [
                                _vm._v(
                                  " " +
                                    _vm._s(item.commodityCurrency) +
                                    " " +
                                    _vm._s(
                                      _vm.toCurrencyThousand(
                                        item.commoditySellPrice
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c("span", { attrs: { id: "helper" } }),
      _c("div", {
        class: ["instructions"],
        domProps: { innerHTML: _vm._s(_vm.$xy("recharge.instructions")) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }