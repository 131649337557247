var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasRoles
        ? _c(
            "van-form",
            { ref: "form", attrs: { "submit-on-enter": false } },
            [
              _c("van-field", {
                attrs: {
                  "is-link": "",
                  readonly: "",
                  label: _vm.$sys("account.roleName"),
                  placeholder: _vm.$sys("account.roleName"),
                  rules: [
                    { required: true, message: _vm.$sys("warn.roleEmpty") },
                  ],
                },
                on: {
                  click: function ($event) {
                    _vm.visibleRole = true
                  },
                },
                model: {
                  value: _vm.roleName,
                  callback: function ($$v) {
                    _vm.roleName = $$v
                  },
                  expression: "roleName",
                },
              }),
              _vm.showCountry
                ? _c("van-field", {
                    attrs: {
                      "is-link": "",
                      readonly: "",
                      label: _vm.$sys("account.country"),
                      placeholder: _vm.$sys("form.placeholder.select", {
                        name: "",
                      }),
                    },
                    on: {
                      click: function ($event) {
                        _vm.visibleCountry = true
                      },
                    },
                    model: {
                      value: _vm.countryName,
                      callback: function ($$v) {
                        _vm.countryName = $$v
                      },
                      expression: "countryName",
                    },
                  })
                : _vm._e(),
              _c("MobilePopRoleSelect", {
                attrs: {
                  source: _vm.roleList,
                  visible: _vm.visibleRole,
                  "only-show": true,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visibleRole = $event
                  },
                  close: function ($event) {
                    return _vm.close()
                  },
                  confirm: _vm.handleRoleConfirm,
                },
              }),
              _c(
                "van-popup",
                {
                  attrs: {
                    "get-container": "body",
                    round: "",
                    position: "bottom",
                  },
                  model: {
                    value: _vm.visibleCountry,
                    callback: function ($$v) {
                      _vm.visibleCountry = $$v
                    },
                    expression: "visibleCountry",
                  },
                },
                [
                  _c("van-picker", {
                    attrs: {
                      "show-toolbar": "",
                      columns: _vm.countryLangList,
                      "value-key": "name",
                    },
                    on: {
                      confirm: _vm.onConfirmCountry,
                      cancel: function ($event) {
                        _vm.visibleCountry = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "van-button",
                {
                  staticClass: "btn_submit",
                  attrs: {
                    block: "",
                    type: "info",
                    color: "#f3981d",
                    loading: _vm.isBtnLoading,
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$sys("btn.confirm")) + " ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "tips_no_roles" }, [
                _vm._v(
                  _vm._s(
                    _vm.$sys("account.noRoles", { name: `【${_vm.nickname}】` })
                  )
                ),
              ]),
              _c(
                "van-button",
                {
                  staticClass: "btn_submit",
                  attrs: {
                    block: "",
                    type: "info",
                    color: "#f3981d",
                    loading: _vm.isBtnLoading,
                  },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v(" " + _vm._s(_vm.$sys("btn.confirm")) + " ")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }