var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step_login flex-column-center" }, [
    _c("div", { staticClass: "flex-column-center" }, [
      _c("div", { staticClass: "game_icon_box flex-center" }, [
        _c("img", {
          staticClass: "game_icon",
          attrs: { src: require("images/game/xy-icon.png"), alt: "game-icon" },
        }),
      ]),
      _c("div", { staticClass: "game_name" }, [
        _vm._v(_vm._s(_vm.$xy("game.name"))),
      ]),
      _c(
        "a",
        {
          staticClass: "btn_login flex-center",
          attrs: { href: "javascript:;" },
          on: { click: _vm.handleLogin },
        },
        [_vm._v(_vm._s(_vm.$sys("btn.login")))]
      ),
      _c("p", { staticClass: "tips" }, [
        _vm._v(_vm._s(_vm.$sys("warn.loginTips"))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }